// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@import "/src/themes/palette.scss";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cividas-payment-gateway-frontend-primary: mat.define-palette($pink-palette);
$cividas-payment-gateway-frontend-accent: mat.define-palette(
  $pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$cividas-payment-gateway-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cividas-payment-gateway-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $cividas-payment-gateway-frontend-primary,
      accent: $cividas-payment-gateway-frontend-accent,
      warn: $cividas-payment-gateway-frontend-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cividas-payment-gateway-frontend-theme);

//lealfet styles
@import url("https://unpkg.com/leaflet@1.9.4/dist/leaflet.css");

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, sans-serif !important;
}
a {
  text-decoration: none;
  color: #5b93c0;
  font-weight: 500;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

label {
  margin-bottom: 0;
}

.mat-mdc-raised-button {
  width: 72%;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell,
.mdc-button {
  font-family: "Poppins", sans-serif !important;
}
